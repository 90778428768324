
.swap-commission-form > div {
    display: grid;
    grid-template-columns: 1fr 1fr 130px auto 50px;
    gap: 10px;
    align-items: center;
    justify-content: start;
}

.swap-item-bound {
    width: 100px;
}

.swap-item-rate {
    width: 130px;
}

.add-swap-item-button {
    grid-column: -2;
}