.commission-client-card {
    display: flex;
    gap: 20px;
    gap: 70px;

    border-left: 2px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;

    max-width: 900px;
    padding: 5px 35px;
}

/* .commission-client-card:hover {
    background-color: rgba(0, 0, 0, 0.062);
} */

.commission-form {
    display: flex;
    align-items: center;
}