*,
::before,
::after {
  box-sizing: border-box;
}

.App {
  color: #383838;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}