.content {
    display: flex;
    min-height: 100%;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 50px;
}

h1,
h2,
h3 {
    color: #555555;
}

main {
    padding: 45px;
    height: 100%;
}