.deal-filter {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;

    margin: 20px 0;
}

.deal-filter > div {
    /* flex: 1 1 80px; */
    /* flex-grow: 1; */
    min-width: 260px;
}

