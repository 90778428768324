.clientList {
    display: flex;
    flex-wrap: wrap;

    padding: 2px;

    overflow-y: auto;
}

.clientList > div {
    margin-right: 30px;
    margin-bottom: 30px;
}