.login {
    text-align: center;

    margin-left: auto;
    margin-right: auto; 

    margin-top: 150px;

    width: 50%;
}

@media screen and (width >= 1250px) {
    .login {
        width: 40%;
    }
}