.card {
    padding: 15px 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    width: 15rem;
    height: 10rem;
}

.card>h3 {
    text-align: left;

    max-height: 100px;
    width: 160px;

    display: -webkit-box;
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.code {
    font-weight: 500;
    letter-spacing: 1px;
}

.card-description {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    width: 100%;
}

.card-description>* {
    margin-right: 20px;
}